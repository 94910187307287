<template>
  <div>
    <div class="golstats-data-container">
      <div class="golstats-data-container__custom-header" :style="headerStyles">
        <img :src="headerIcon" width="28" alt="Logo" />
        <span>Datos Golstats</span>
      </div>
      <div class="golstats-data-container__view">
        <div class="golstats-data-new-20" v-if="newsPrint.length > 0">
          <BCarousel
            :key="updateC + 1"
            class="myCarousel-Movil"
            :interval="5000"
            autoplay
            :indicator-inside="false"
            :pause-info="true"
            :progress="true"
            progress-type="is-primary1"
          >
            <template v-for="(newItem, k) in newsPrint">
              <BCarouselItem :key="k + 11 + 'm'">
                <div class="golstats-data-new-20__item">
                  <div class="item-new-golstats">
                    <div class="item-new-golstats__container-jersey" :class="{ 'is-player': newItem.isType === 1 }">
                      <img
                        :src="newItem.isType == 1 ? `${playersImagesUrl}${newItem.playerId}.png` : newItem.logo"
                        :style="newItem.isType === 1 ? `border-radius: 5px;` : ``"
                      />
                      <div
                        v-if="newItem.isType == 1"
                        class="item-new-golstats__container-jersey__country"
                        :style="{ 'background-image': `url(${newItem.logo})` }"
                      ></div>
                    </div>
                    <div class="item-new-golstats__container-new">
                      <div class="item-new-golstats__container-new__title">{{ newItem.title }}</div>
                      <div
                        class="item-new-golstats__container-new__nota"
                        v-html="getFormattedBullet(newItem.description)"
                      ></div>
                    </div>
                  </div>
                </div>
              </BCarouselItem>
            </template>
          </BCarousel>
        </div>
        <div v-else class="container-not-found-data">
          <div class="container-not-found-data__img-search">
            <img src="/images/graf_busqueda@2x.png" width="74" />
          </div>
          <div class="container-not-found-data__title-info">Sin resultados</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { BCarousel, BCarouselItem } from 'buefy/dist/esm/carousel';
const mediaPlayerTitles = {
  1: 'Balones recuperados en cancha rival',
  2: 'Pases acertados en 4/4',
  3: 'Balones recuperados',
  4: 'Balones recuperados',
  5: '1 vs 1 exitoso ofensivo que terminan en gol',
  6: '1 vs 1 exitoso ofensivo',
  7: '1 vs 1 exitoso ofensivo',
  8: 'Participación en goles',
  9: '1 vs 1 exitoso ofensivo',
  10: 'Pases acertados en cancha rival',
  11: 'Pases acertados',
  12: 'Pases acertados',
  13: 'Tiros a gol',
  14: 'Tiros a gol',
  15: 'Jugadas relevantes',
  16: 'Jugadas relevantes',
  17: 'Centros',
  18: 'Faltas recibidas',
  19: 'Faltas cometidas',
  20: 'Faltas cometidas',
};
export default {
  name: 'GolStatsDataGS2',
  components: { BCarousel, BCarouselItem },
  props: {
    custom: {
      type: Object,
      default: null,
    },
    tournament: {
      type: Number,
    },
    season: {
      type: Object,
      default: null,
    },
    awaitForSeason: {
      type: Boolean,
      default: true,
    },
    tournaments: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    headerStyles() {
      return {
        color: this.custom.color,
        background: this.custom.headerBackground,
        borderColor: this.custom.headerColor,
      };
    },
    headerIcon() {
      if (this.$route.query.logo) {
        return this.$route.query.logo;
      } else {
        if (this.custom != null) {
          if (Object.keys(this.custom).length) {
            return this.custom.logo;
          } else {
            return 'https://az755631.vo.msecnd.net/content/shieldLogoPc.png';
          }
        } else {
          return 'https://az755631.vo.msecnd.net/content/shieldLogoPc.png';
        }
      }
    },
  },
  data() {
    return {
      updateC: 0,
      arrayColors: {
        //liga mx
        1: { color: '#1B2956', shadow: '#FFFFFF' },
        2: { color: '#1B2956', shadow: '#FFFFFF' },
        4: { color: '#FFFFFF', shadow: 'transparent' },
        5: { color: '#FFFFFF', shadow: 'transparent' },
        7: { color: '#1B2956', shadow: '#FFFFFF' },
        8: { color: '#FFFFFF', shadow: 'transparent' },
        9: { color: '#1B2956', shadow: '#FFFFFF' },
        10: { color: '#1B2956', shadow: '#FFFFFF' },
        11: { color: '#FFC68F', shadow: 'transparent' },
        12: { color: '#FFFFFF', shadow: 'transparent' },
        13: { color: '#FFFFFF', shadow: 'transparent' },
        14: { color: '#1B2956', shadow: '#FFFFFF' },
        16: { color: '#FFFFFF', shadow: 'transparent' },
        17: { color: '#FFFFFF', shadow: 'transparent' },
        18: { color: '#FFFFFF', shadow: 'transparent' },
        173: { color: '#FFFFFF', shadow: 'transparent' },
        205: { color: '#147F55', shadow: '#FFFFFF' },
        3014: { color: '#FFFFFF', shadow: 'transparent' },
        //expan
        482: { color: '#1D1D1D', shadow: '#FFFFFF' }, //alebrijes
        3: { color: '#FFFFFF', shadow: 'transparent' }, //atalante
        3161: { color: '#1D1D1D', shadow: '#FFFFFF' }, //cancun
        908: { color: '#1B2956', shadow: '#FFFFFF' }, //cimarrones
        3162: { color: '#1B2956', shadow: '#FFFFFF' }, //morellia
        557: { color: '#1B2956', shadow: '#FFFFFF' }, //celaya
        554: { color: '#FFFFFF', shadow: 'transparent' }, //correaceminos
        195: { color: '#FFFFFF', shadow: 'transparent' }, //dorados
        757: { color: '#FFFFFF', shadow: 'transparent' }, //mineros
        3166: { color: '#FFC68F', shadow: 'transparent' }, //tabasco
        3328: { color: '#1B2956', shadow: '#FFFFFF' }, //rayados
        3163: { color: '#1B2956', shadow: '#FFFFFF' }, //tapatio
        3164: { color: '#FFFFFF', shadow: 'transparent' }, //tepatitaln
        3165: { color: '#FFFFFF', shadow: 'transparent' }, //tlaxcala
        1603: { color: '#1D1D1D', shadow: '#FFFFFF' }, //tampico
        235: { color: '#1D1D1D', shadow: '#FFFFFF' }, //udg
        592: { color: '#1B2956', shadow: '#FFFFFF' }, //tapachula
        //femenil
        2734: { color: '#1B2956', shadow: '#FFFFFF' }, //america
        2735: { color: '#FFFFFF', shadow: 'transparent' }, //atlas
        2978: { color: '#FFFFFF', shadow: 'transparent' }, //san luis
        2749: { color: '#FFFFFF', shadow: 'transparent' }, //xolos
        2737: { color: '#FFFFFF', shadow: 'transparent' }, //cruz azul
        3018: { color: '#FFFFFF', shadow: 'transparent' }, //juarez
        2736: { color: '#1B2956', shadow: '#FFFFFF' }, //chivas
        2738: { color: '#FFFFFF', shadow: 'transparent' }, //leon
        2740: { color: '#FFFFFF', shadow: 'transparent' }, //mazatlan
        2741: { color: '#1B2956', shadow: '#FFFFFF' }, //monterrey
        2742: { color: '#147F55', shadow: '#FFFFFF' }, //necaxa
        2743: { color: '#1B2956', shadow: '#FFFFFF' }, //pachuca
        2744: { color: '#1B2956', shadow: '#FFFFFF' }, //puebla
        2746: { color: '#FFFFFF', shadow: 'transparent' }, //queretaro
        2747: { color: '#1B2956', shadow: '#FFFFFF' }, //santos
        2748: { color: '#FFFFFF', shadow: 'transparent' }, //tigres
        2750: { color: '#FFFFFF', shadow: 'transparent' }, //toluca
        2745: { color: '#FFC68F', shadow: 'transparent' }, //pumas
      },
      //https://qxk21vj1q7.execute-api.us-east-2.amazonaws.com/prod/v2/seasons/1535/teams/1/players/151/news
      // urlDataPlayerUserOnly: 'https://qxk21vj1q7.execute-api.us-east-2.amazonaws.com/prod/v2/seasons',
      urlDataPlayerUserOnly: 'https://qxk21vj1q7.execute-api.us-east-2.amazonaws.com/prod/seasons',
      url: 'https://17zyhpyc7c.execute-api.us-west-2.amazonaws.com/prod/tournaments',
      // urlData: 'https://qxk21vj1q7.execute-api.us-east-2.amazonaws.com/prod/seasons',
      listTournaments: [],
      listSeasonsByTournament: [],
      listSeasons: {},
      groupedSeasons: null,
      tournamentSelected: {},
      seasonSelected: {},
      newsData: [],
      newsPrint: [],
      playersImagesUrl: 'https://az755631.vo.msecnd.net/players-profile/',
    };
  },
  created() {
    try {
      window.addEventListener('resize', this.myEventHandler);
      if (!this.awaitForSeason) {
        this.fillData();
      }
    } catch (e) {
      throw new Error(e);
    }
  },
  watch: {
    season: {
      immediate: true,
      handler: function(newValue) {
        if (newValue != null) {
          this.seasonSelected = this.season.id;
          this.fillData();
        }
      },
    },
    newsData() {
      this.shuffle(this.newsData);
      var idsShow = [];
      if (localStorage.getItem('newsDataStorage')) {
        idsShow = localStorage.getItem('newsDataStorage');
        var temp_idsShow = idsShow.split(',');
        for (var a in temp_idsShow) {
          temp_idsShow[a] = parseInt(temp_idsShow[a], 10);
        }
        idsShow = temp_idsShow;
      } else {
        idsShow = [];
        localStorage.setItem('newsDataStorage', idsShow);
      }
      if (this.newsData.length == idsShow.length) {
        idsShow = [];
      }
      var idShowTemp = [];
      var contPrint = 0;
      for (let index = 0; index < this.newsData.length; index++) {
        if (!idsShow.includes(this.newsData[index].order)) {
          this.newsPrint.push(this.newsData[index]);
          contPrint += 1;
          if (contPrint <= 2) {
            idShowTemp.push(this.newsData[index].order);
            idsShow.push(this.newsData[index].order);
          }
        }
      }
      for (let index = 0; index < this.newsData.length; index++) {
        if (idsShow.includes(this.newsData[index].order)) {
          if (!idShowTemp.includes(this.newsData[index].order)) {
            this.newsPrint.push(this.newsData[index]);
          }
        }
      }
      localStorage.setItem('newsDataStorage', idsShow);
    },
  },
  methods: {
    async fillData() {
      try {
        var urlSendAxios = '';
        urlSendAxios = this.urlDataPlayerUserOnly + '/' + this.seasonSelected + '/news';
        const { data: news } = await axios
          .get(urlSendAxios)
          .then(response => response)
          .catch(e => {
            if (this.retries > 0) {
              this.retries = this.retries - 1;
              return this.fillData();
            }
            return e;
          });
        if (news) {
          this.newsPrint = [];
          this.newsData = [];
          for (let index = 0; index < news.news.length; index++) {
            const newItem = {
              category: news.news[index].categories,
              playingAs: news.news[index].playing_as,
              section: news.news[index].section,
              match_lapse: news.news[index].match_lapse,
              isType: news.news[index].is_player,
              title: news.news[index].title,
              description: news.news[index].description,
              jersey: news.news[index].jersey,
              logo: news.news[index].team_logo,
              team: news.news[index].team_id,
              order: news.news[index].order,
              playerId: news.news[index].player_id,
              generalName: mediaPlayerTitles[news.news[index].id.toString()],
            };
            newItem.logo = newItem.logo.replace('teams-80', 'teams-150');
            this.newsData.push(newItem);
          }
        } else {
          this.newsPrint = [];
          this.newsData = [];
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    setOpenMedia() {
      // let jsonConfig = {
      //   seasonId: this.getSeasonSelected,
      //   tournaments: this.tournamentsForMediaPlayer,
      //   categoryIds: item.category,
      //   mainSectionType: 3,
      //   // isType means player news
      //   playListType: item.isType !== 0 ? 2 : 3,
      //   gameIds: null,
      //   matchResult: [1, 2, 3],
      //   playData: {},
      //   advancedFilter: {
      //     playListTitle: item.generalName,
      //     mainCategoryId: {},
      //     playingAs: item.playingAs ? [item.playingAs] : [1, 2],
      //     timeSections: item.section ? [item.section] : [1, 2, 3, 4, 5, 6],
      //     fieldSections: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
      //   },
      // };
      // if (item.isType == 1) {
      //   jsonConfig.playerIds = [item.playerId];
      //   jsonConfig.teamId = 0;
      // } else {
      //   jsonConfig.playerIds = [];
      //   jsonConfig.teamId = item.team;
      // }
      // this.setModalInitialState(jsonConfig);
      // this.setIsModalOpen(true);
    },
    getFormattedBullet(bullet) {
      let formattedBullet = bullet;
      const matches = bullet.match(/\*/g);
      if (matches && matches.length) {
        if (matches.length % 2 === 0) {
          for (let i = 1; i <= matches.length; i++) {
            if (i % 2 === 0) {
              formattedBullet = formattedBullet.replace('*', '</span>');
            } else {
              formattedBullet = formattedBullet.replace(
                '*',
                // eslint-disable-next-line max-len
                '<span class="item-click-text"  style="font-family: Avenir-Pro-Bold; ">',
              );
            }
          }
        } else {
          formattedBullet = bullet.replaceAll('*', '');
        }
      }
      return `${formattedBullet}`;
    },
    shuffle(a) {
      var j, x, i;
      for (i = a.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        x = a[i];
        a[i] = a[j];
        a[j] = x;
      }
      return a;
    },
  },
};
</script>
<style>
.golstats-data-new-20 .carousel-arrow .icon {
  color: #428ee6;
}

/* bug-cambio 560*/
.progress.is-primary1::-webkit-progress-value {
  background-color: #cbee6b;
}

.progress.is-primary1::-moz-progress-bar {
  background-color: #cbee6b;
}

.progress.is-primary1::-ms-fill {
  background-color: #cbee6b;
}

.progress.is-primary1:indeterminate {
  background-image: linear-gradient(to right, #cbee6b 30%, #ededed 30%);
}
/* bug-cambio 560*/
.golstats-data-new-20 .carousel-indicator {
  display: none !important;
}
.golstats-data-new-20 .carousel-arrow.is-hovered {
  opacity: 1 !important;
}

html > body {
  padding-top: 0 !important;
}
.is-fullheight-with-navbar-and-footer,
.is-fullheight-with-navbar-and-footer-mobile {
  height: 100%;
}
.golstats-data-new-20 .carousel-arrow .icon.has-icons-right {
  right: 39% !important;
  top: 380px !important;
}
.golstats-data-new-20 .carousel-arrow .icon.has-icons-right:hover {
  color: #428ee6 !important;
  border-color: #428ee6 !important;
}
.golstats-data-new-20 .carousel-arrow .icon.has-icons-left:hover {
  color: #428ee6 !important;
  border-color: #428ee6 !important;
}

.golstats-data-new-20 .carousel-arrow .icon.has-icons-left {
  left: 39% !important;
  top: 380px !important;
}
@media screen and (max-width: 1328px) {
  .golstats-data-new-20 .carousel-arrow .icon.has-icons-right {
    right: 45% !important;
    top: 320px !important;
  }
  .golstats-data-new-20 .carousel-arrow .icon.has-icons-left {
    left: 45% !important;
    top: 320px !important;
  }
}
@media screen and (max-width: 1228px) {
  .golstats-data-new-20 .carousel-arrow .icon.has-icons-right {
    right: 44% !important;
  }
  .golstats-data-new-20 .carousel-arrow .icon.has-icons-left {
    left: 44% !important;
  }
}
@media screen and (max-width: 1068px) {
  .golstats-data-new-20 .carousel-arrow .icon.has-icons-right {
    right: 43% !important;
  }
  .golstats-data-new-20 .carousel-arrow .icon.has-icons-left {
    left: 43% !important;
  }
}
@media screen and (max-width: 878px) {
  .golstats-data-new-20 .carousel-arrow .icon.has-icons-right {
    right: 42% !important;
  }
  .golstats-data-new-20 .carousel-arrow .icon.has-icons-left {
    left: 42% !important;
  }
}
@media screen and (max-width: 800px) {
  .golstats-data-new-20 .carousel-arrow .icon.has-icons-right {
    right: 41% !important;
  }
  .golstats-data-new-20 .carousel-arrow .icon.has-icons-left {
    left: 41% !important;
  }
}
@media screen and (max-width: 710px) {
  .golstats-data-new-20 .carousel-arrow .icon.has-icons-right {
    right: 40% !important;
  }
  .golstats-data-new-20 .carousel-arrow .icon.has-icons-left {
    left: 40% !important;
  }
}
@media screen and (max-width: 640px) {
  .golstats-data-new-20 .carousel-arrow .icon.has-icons-right {
    right: 38% !important;
  }
  .golstats-data-new-20 .carousel-arrow .icon.has-icons-left {
    left: 38% !important;
  }
}
@media screen and (max-width: 520px) {
  .golstats-data-new-20 .carousel-arrow .icon.has-icons-right {
    right: 37% !important;
  }
  .golstats-data-new-20 .carousel-arrow .icon.has-icons-left {
    left: 37% !important;
  }
}
@media screen and (max-width: 480px) {
  .golstats-data-new-20 .carousel-arrow .icon.has-icons-right {
    right: 35% !important;
  }
  .golstats-data-new-20 .carousel-arrow .icon.has-icons-left {
    left: 35% !important;
  }
}
@media screen and (max-width: 428px) {
  .golstats-data-new-20 .carousel-arrow .icon.has-icons-right {
    right: 41% !important;
    top: 88px !important;
  }
  .golstats-data-new-20 .carousel-arrow .icon.has-icons-left {
    left: 41% !important;
    top: 88px !important;
  }
}
@media screen and (max-width: 360px) {
  .golstats-data-new-20 .carousel-arrow .icon.has-icons-right {
    right: 40% !important;
  }
  .golstats-data-new-20 .carousel-arrow .icon.has-icons-left {
    left: 40% !important;
  }
}
@media screen and (max-width: 320px) {
  .golstats-data-new-20 .carousel-arrow .icon.has-icons-right {
    right: 39% !important;
  }
  .golstats-data-new-20 .carousel-arrow .icon.has-icons-left {
    left: 39% !important;
  }
}
</style>
<style lang="scss" scoped>
.myCarousel-Movil {
  display: inline-block;
  width: 100%;
}
.container-not-found-data {
  width: 100%;
  border-radius: 5px;
  height: 372px;
  display: inline-block;
  @media screen and (max-width: 428px) {
    height: 108px;
  }
  &__img-search {
    width: 100%;
    margin-top: 158px;
    @media screen and (max-width: 428px) {
      margin-top: 6px;
      width: 100%;
    }
  }
  &__title-info {
    width: 100%;
    margin-top: 14px;
    text-align: center;
    font-family: 'Circular-Std';
    font-size: 19px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.52px;
    @media screen and (max-width: 428px) {
      margin-top: -3px;
    }
  }
  &__subtitle-info {
    width: 100%;
    text-align: center;
    margin-top: 4px;
    font-family: 'Avenir-Roman';
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.12px;
    color: #9a9a9a;
  }
}
.item-new-golstats {
  width: 100%;
  height: 367px;
  margin-top: -25px;
  @media screen and (max-width: 428px) {
    display: inline-flex;
    height: 151px;
  }
  &__container-new {
    position: absolute;
    width: 92%;
    height: 114px;
    margin-left: 4%;
    float: left;
    text-align: center;
    top: 46%;
    @media screen and (max-width: 628px) {
      top: 37%;
      height: 164px;
    }
    @media screen and (max-width: 428px) {
      top: 0px;
      margin-top: 34px;
      height: 68px;
      position: relative;
    }
    &__title {
      width: 100%;
      height: 22px;
      font-family: 'Circular-Std-Bold';
      font-size: 22px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 22px;
      letter-spacing: -0.46px;
      text-align: center;
      color: #f00;

      margin-bottom: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @media screen and (max-width: 1328px) {
        font-size: 26px;
      }
      @media screen and (max-width: 1100px) {
        font-size: 25px;
      }
      @media screen and (max-width: 900px) {
        font-size: 24px;
      }
      @media screen and (max-width: 800px) {
        font-size: 22px;
      }
      @media screen and (max-width: 428px) {
        font-size: 17px;
        text-align: left;
        height: 17px;
        line-height: 16px;
        margin-bottom: 1px;
      }
    }
    &__nota {
      width: 100%;
      min-height: 50px;
      font-family: 'Avenir-Medium';
      font-size: 17px;
      height: 95px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 23px;
      letter-spacing: -0.4px;
      text-align: center;
      color: #132839;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      @media screen and (max-width: 1328px) {
        font-size: 20px;
      }
      @media screen and (max-width: 1100px) {
        font-size: 19px;
      }
      @media screen and (max-width: 900px) {
        font-size: 18px;
      }
      @media screen and (max-width: 800px) {
        font-size: 17px;
      }
      @media screen and (max-width: 628px) {
        -webkit-line-clamp: 5;
        height: 125px;
      }
      @media screen and (max-width: 528px) {
        -webkit-line-clamp: 6;
        height: 135px;
      }
      @media screen and (max-width: 428px) {
        -webkit-line-clamp: 3;
        height: 40px;
        font-size: 12px;
        text-align: left;
        line-height: 16px;
        width: 97%;
      }
      &__decoration {
        font-family: 'Avenir-Bold';
        text-decoration: underline;
      }
    }
  }
  &__container-jersey {
    width: 108px;
    height: fit-content;
    margin-left: 26px;
    margin-top: 25px;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-74%, -153%);
    @media screen and (max-width: 628px) {
      width: 88px;
      transform: translate(-79%, -205%);
    }
    @media screen and (max-width: 428px) {
      width: 60px;
      top: 0;
      transform: none;
      left: 0;
      position: relative;
      margin-top: 45px;
    }
    &__country {
      width: 42px;
      height: 42px;
      position: absolute;
      bottom: -4px;
      left: -16px;

      -webkit-background-size: contain;
      -moz-background-size: contain;
      -o-background-size: contain;
      background-size: contain;
      @media screen and (max-width: 428px) {
        width: 28px;
        height: 28px;
        left: -10px;
        bottom: -1px;
      }
    }
  }
}
.golstats-data-new-20 {
  width: 100%;
  height: 400px;
  margin: 0 auto;
  @media screen and (max-width: 1328px) {
    height: 366px;
  }
  @media screen and (max-width: 428px) {
    height: 106px;
  }
  &__item {
    width: 100%;
    height: 400px;
    float: left;
    @media screen and (max-width: 1328px) {
      height: 366px;
    }
    @media screen and (max-width: 428px) {
      height: 106px;
    }
  }
}

.golstats-data-container {
  width: 100%;
  height: 410px;
  @media screen and (max-width: 428px) {
    height: 151px;
  }
  &__custom-header {
    height: 40px;
    font-family: 'Circular-Std-Medium';
    line-height: 40px;
    position: relative;
    overflow: hidden;
    border-radius: 3px 3px 0 0;
    img {
      width: 28px;
      margin-left: 11px;
      margin-top: 4px;
      float: left;
    }
    span {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 18px;
      transform: translate(-50%, -50%);
    }
  }
  &__view {
    margin: 0 auto;
    margin-bottom: 30px;
    height: 370px;
  }
}

.is-player {
  width: 80px;
  @media screen and (max-width: 628px) {
    width: 62px;
  }
  @media screen and (max-width: 428px) {
    width: 44px;
    margin-top: 38px;
  }
  height: default;
}
</style>
